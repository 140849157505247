:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  /* webkit only issue #791 */
  z-index: 0;
}
.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--close-on-click {
  cursor: pointer;
}
.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1 auto;
  padding: 6px;
  display: flex;
  align-items: center;
}
.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1 1;
}
.Toastify__toast-icon {
  -webkit-margin-end: 10px;
          margin-inline-end: 10px;
  width: 20px;
  flex-shrink: 0;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}
.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}
.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}
.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}
.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}
.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}
.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}
.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  align-self: flex-start;
}
.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}
.Toastify__close-button > svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}
.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}
.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
}
.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}
.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}
.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }
  75% {
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    transform: translate3d(5px, 0, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  75% {
    transform: translate3d(0, 10px, 0);
  }
  90% {
    transform: translate3d(0, -5px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
}
@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}
.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}
.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}
.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}
.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}
.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}
.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}
.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}
.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}
@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}
@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}
@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}
.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}
.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}
.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}
.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}
.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}
.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}
.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=ReactToastify.css.map */
/*  imported from Icons.module.css  */

.Icons-module__animatedPlusMinus___3nTp3 {
  cursor: pointer;
  height: 14px;
  width: 14px;
}

.Icons-module__animatedPlusMinus___3nTp3:before,
.Icons-module__animatedPlusMinus___3nTp3:after {
  background: var(--color-neutral-7);
  content: '';
  height: 2px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 14px;
  transition: transform 250ms ease;
  border-radius: 1px;
}

.Icons-module__animatedPlusMinus___3nTp3:after {
  transform-origin: center;
}

.Icons-module__animatedPlusMinus___3nTp3.Icons-module__collapsed___1xWrw:after {
  transform: rotate(90deg);
}

.Icons-module__animatedPlusMinus___3nTp3.Icons-module__collapsed___1xWrw:before {
  transform: rotate(180deg);
}

.Icons-module__checkmarkTransition___3dLXb * {
  transition: fill 200ms, stroke 200ms;
}

.Icons-module__backgroundIconWrapper___3A6Fk {
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}
/*  imported from IconBackground.module.css  */

.IconBackground-module__backgroundIconWrapper___3qkzg {
  display: flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}

/*  imported from AkvaButton.module.css  */

.AkvaButton-module__button___VAnyu {
  height: 2.5rem;
  padding: 0.5rem 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-size: var(--sizing-font-normal);
  font-weight: bold;
  line-height: 1rem;
  border: none;
  border-radius: 0.25rem;
}
.AkvaButton-module__button___VAnyu[data-icon-position='left'] {
  flex-direction: row-reverse;
}

/* Small size button paddings */

.AkvaButton-module__button___VAnyu[data-icon-size='small'] {
  height: 2rem;
  font-size: var(--sizing-font-small);
  padding: 0.375rem 1rem;
}

.AkvaButton-module__button___VAnyu[data-icon-size='small'][data-icon-variant='icon-button'] {
  padding: 0.375rem;
}
.AkvaButton-module__button___VAnyu[data-icon-size='small'][data-icon-position='left'][data-has-icon='true'][data-icon-variant='text-button'] {
  padding: 0.375rem 1rem 0.375rem 0.75rem;
}

.AkvaButton-module__button___VAnyu[data-icon-size='small'][data-icon-position='right'][data-has-icon='true'][data-icon-variant='text-button'] {
  padding: 0.375rem 0.75rem 0.375rem 1rem;
}

/* Normal size button paddings */

.AkvaButton-module__button___VAnyu[data-icon-size='normal'][data-icon-variant='icon-button'] {
  padding: 0.5rem;
}
.AkvaButton-module__button___VAnyu[data-icon-size='normal'][data-icon-position='left'][data-has-icon='true'][data-icon-variant='text-button'] {
  padding: 0.5rem 1.5rem 0.5rem 1.25rem;
}
.AkvaButton-module__button___VAnyu[data-icon-size='normal'][data-icon-position='right'][data-has-icon='true'][data-icon-variant='text-button'] {
  padding: 0.5rem 1.25rem 0.5rem 1.5rem;
}

/* Button events and colors */

.AkvaButton-module__primary___3JzKZ {
  background-color: var(--color-primary-1);
  color: var(--color-neutral-1);
}
.AkvaButton-module__primary___3JzKZ svg * {
  fill: var(--color-neutral-1);
}

.AkvaButton-module__primary___3JzKZ:hover {
  background-color: var(--color-primary-2);
}

.AkvaButton-module__primary___3JzKZ:active {
  background-color: var(--color-primary-3);
}

.AkvaButton-module__primary___3JzKZ:disabled {
  background-color: var(--color-primary-4);
  color: var(--color-neutral-4);
  cursor: default;
}
.AkvaButton-module__primary___3JzKZ:disabled svg * {
  fill: var(--color-neutral-4);
}

.AkvaButton-module__secondary___3_Vct {
  background-color: var(--color-secondary-1);
  color: var(--color-text-2);
}
.AkvaButton-module__secondary___3_Vct svg * {
  fill: var(--color-text-2);
}

.AkvaButton-module__secondary___3_Vct:hover {
  background-color: var(--color-secondary-2);
}

.AkvaButton-module__secondary___3_Vct:active {
  background-color: var(--color-secondary-3);
}

.AkvaButton-module__secondary___3_Vct:disabled {
  background-color: var(--color-secondary-4);
  color: var(--color-text-5);
  cursor: default;
}
.AkvaButton-module__secondary___3_Vct:disabled svg * {
  fill: var(--color-text-5);
}

.AkvaButton-module__tertiary___1Jqfk {
  background-color: transparent;
  border: 1px solid var(--color-tertiary);
  color: var(--color-text-4);
}
.AkvaButton-module__tertiary___1Jqfk svg * {
  fill: var(--color-text-4);
}

.AkvaButton-module__tertiary___1Jqfk:hover {
  border-color: var(--color-text-4);
  color: var(--color-text-2);
}
.AkvaButton-module__tertiary___1Jqfk:hover svg * {
  fill: var(--color-text-2);
}

.AkvaButton-module__tertiary___1Jqfk:enabled:active {
  border-color: var(--color-text-4);
  background-color: var(--color-secondary-3);
  color: var(--color-text-2);
}
.AkvaButton-module__tertiary___1Jqfk:enabled:active svg * {
  fill: var(--color-text-2);
}

.AkvaButton-module__tertiary___1Jqfk:disabled {
  color: var(--color-text-5);
  border-color: var(--color-secondary-1);
  cursor: default;
}

.AkvaButton-module__tertiary___1Jqfk:disabled svg * {
  fill: var(--color-text-5);
}
/* imported from AkvaCheckbox.module.css */

.AkvaCheckbox-module__checkbox___LG5dq {
  --button-size: 1.5rem;
  --button-border-width: 1px;
  --button-inner-height: 0.75rem;
  --label-font-size: 1rem;
  --top-margin: var(--sizing-tiny);
  --height: calc(var(--button-size) + var(--top-margin) * 2);

  font-size: var(--label-font-size);
  height: var(--height);
  position: relative;

  display: flex;
  justify-content: flex-start;
  gap: 0.75rem;
  align-items: center;

  cursor: pointer;
  transition: color 500ms;
}

.AkvaCheckbox-module__checkbox___LG5dq[data-disabled='true'] {
  cursor: default;
  color: var(--color-text-5);
}

.AkvaCheckbox-module__checkbox___LG5dq[data-label-position='right'] {
  flex-direction: row-reverse;
}

.AkvaCheckbox-module__checkbox___LG5dq[data-label-position='right'][data-spacing='packed'] {
  justify-content: flex-end;
}

.AkvaCheckbox-module__checkbox___LG5dq[data-spacing='space-between'] {
  justify-content: space-between;
}

.AkvaCheckbox-module__checkbox___LG5dq input {
  height: initial;
  width: initial;
  margin-bottom: 0;
  opacity: 0;
  position: absolute;
  z-index: -999;
}

/*Custom checkbox*/

.AkvaCheckbox-module__checkboxCustom___9LwsW {
  display: block;
  position: relative;
  border: var(--button-border-width) solid var(--color-tertiary);
  border-radius: 0.25rem;
  padding: 0.625rem;
  height: var(--button-size);
  width: var(--button-size);
  background-color: var(--color-neutral-2);
}

.AkvaCheckbox-module__checkbox___LG5dq:hover input[type='checkbox']:enabled ~ .AkvaCheckbox-module__checkboxCustom___9LwsW {
  border-color: var(--color-text-4);
}

.AkvaCheckbox-module__checkbox___LG5dq input[type='checkbox']:disabled ~ .AkvaCheckbox-module__checkboxCustom___9LwsW {
  border-color: var(--color-neutral-7);
}

/*Custom checkmark as SVG */

.AkvaCheckbox-module__checkbox___LG5dq input[type='checkbox']:checked ~ .AkvaCheckbox-module__checkboxCustom___9LwsW svg {
  position: absolute;
  content: '';
  width: 1.375rem;
  height: 1.375rem;
  top: 0rem;
  left: 0rem;
  display: block;
}

.AkvaCheckbox-module__checkbox___LG5dq input[type='checkbox'] ~ .AkvaCheckbox-module__checkboxCustom___9LwsW svg {
  display: none;
}

.AkvaCheckbox-module__checkbox___LG5dq svg * {
  fill: var(--color-primary-1);
}

.AkvaCheckbox-module__checkbox___LG5dq input[type='checkbox']:disabled ~ .AkvaCheckbox-module__checkboxCustom___9LwsW svg * {
  fill: var(--color-primary-4);
}
/* imported from AkvaChipsButton.module.css */

.AkvaChipsButton-module__chipsBar___3zPCD {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.AkvaChipsButton-module__chipsBar___3zPCD input[type='radio'] {
  opacity: 0;
  height: 0;
  width: 0;
  display: none;
}

.AkvaChipsButton-module__chipsButton___3hl_g {
  cursor: pointer;

  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.25rem;
  padding: 0.375rem 0.75rem;
  gap: 0.625rem;
  border: 1px solid var(--color-tertiary);
  border-radius: 6.25rem; /*100px*/

  font-weight: normal;
  font-size: var(--sizing-font-small);
  background: transparent;
  color: var(--color-text-4);
}

.AkvaChipsButton-module__chipsButton___3hl_g[data-active='false'] label {
  cursor: pointer;
}

.AkvaChipsButton-module__chipsButton___3hl_g:hover[data-active='false'] {
  color: var(--color-text-2);
  border-color: var(--color-text-4);
}
.AkvaChipsButton-module__chipsButton___3hl_g:active[data-active='false'] {
  background-color: var(--color-primary-1);
  color: var(--color-neutral-1);
  border-color: var(--color-primary-1);
}
.AkvaChipsButton-module__chipsButton___3hl_g[data-active='true'] {
  background-color: var(--color-primary-1);
  color: var(--color-neutral-1);
  border-color: var(--color-primary-1);
  cursor: default;
}
/*  imported from AkvaComboBox.module.css  */

.AkvaComboBox-module__akvaCombobox___q_A-0 {
  --color-background: var(--color-neutral-2);
  --color-background-hover: var(--color-neutral-4);

  --color-border: var(--color-neutral-6);
  --color-border-hover: var(--color-neutral-7);
  --color-border-active: var(--color-primary-1);
  --color-border-error: var(--color-error);

  --color-icon-chevron: var(--color-neutral-7);
  --color-icon-cancel: var(--color-primary-1);

  --color-text: var(--color-text-2);
  --color-text-disbaled: var(--color-text-5);
  --color-placeholder: var(--color-neutral-7);

  --height: 2rem;

  color: var(--color-text-2);

  display: inline-block;
  position: relative;
  padding: 0;
  text-align: left;
}

.AkvaComboBox-module__akvaCombobox__inputWrapper___11jZy {
  display: grid;
  grid-template-columns: 2rem 1fr 2rem 2rem;
  background: var(--color-neutral-3);
  border: 1px solid var(--color-border);
  border-radius: 0.25rem;
  transition: border-color 250ms;
  width: 20rem;
  height: var(--height);
}
.AkvaComboBox-module__akvaCombobox__inputWrapper___11jZy[data-variant='normal'] {
  grid-template-columns: 0.5rem 1fr 2rem 2rem;
}

.AkvaComboBox-module__akvaCombobox__inputWrapper___11jZy[data-has-error='true'] {
  border-color: var(--color-border-error);
}

.AkvaComboBox-module__akvaCombobox__inputWrapper___11jZy[data-has-error='false']:focus-within {
  border-color: var(--color-border-active);
}
.AkvaComboBox-module__akvaCombobox__inputWrapper___11jZy[data-has-error='false'][data-is-disabled='false']:not(:active):not(:focus-within):hover {
  border-color: var(--color-border-hover);
}

.AkvaComboBox-module__akvaCombobox__input___2HftX {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  color: var(--color-text);
  outline: none;
  width: 100%;
}

.AkvaComboBox-module__akvaCombobox__input___2HftX::placeholder {
  color: var(--color-placeholder);
}

.AkvaComboBox-module__akvaCombobox__input___2HftX:disabled,
.AkvaComboBox-module__akvaCombobox__input___2HftX:disabled::placeholder {
  color: var(--color-text-disbaled);
}

.AkvaComboBox-module__akvaCombobox__toggleButton___xLSGR {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  border-color: inherit;
  width: 2rem;
  transition: border-color 250ms;
}
.AkvaComboBox-module__akvaCombobox__toggleButton___xLSGR:enabled {
  cursor: pointer;
}

.AkvaComboBox-module__akvaCombobox__inputWrapper___11jZy:focus-within .AkvaComboBox-module__akvaCombobox__toggleButton___xLSGR {
  border-color: var(--color-border-active);
}

.AkvaComboBox-module__akvaCombobox__toggleButtonOpen___1aNxk svg {
  transform: rotate(180deg);
}

.AkvaComboBox-module__akvaCombobox__resetButton___IGHuI {
  display: grid;
  place-content: center;
  background-color: transparent;
  border: none;
  width: 2rem;

  transition: border-color 250ms;
  cursor: pointer;
}
.AkvaComboBox-module__akvaCombobox__resetButton___IGHuI:disabled {
  cursor: default;
}
.AkvaComboBox-module__akvaCombobox__searchIcon___1LxNI {
  display: grid;
  place-content: center;
}

.AkvaComboBox-module__akvaCombobox__dropdown___1hNxB {
  list-style-type: none;
  background: var(--color-neutral-3);

  max-height: 15rem;
  overflow-y: auto;
  width: 100%;
  /* Use really high zIndex to ensure on top */
  z-index: 9999;
}
.AkvaComboBox-module__akvaCombobox__dropdown___1hNxB ul {
  width: 100%;
  background-color: var(--color-neutral-2);
  margin-top: 2px;
  border-radius: 0.25rem;

  padding: 0.25rem 0;
}
.AkvaComboBox-module__akvaCombobox__item___3UIZ7 {
  list-style-type: none;
  font-size: var(--sizing-font-normal);
  display: flex;
  align-items: center;
  height: var(--height);
  padding: 0 var(--sizing-normal);
  cursor: pointer;

  margin: 0 0.25rem 0.25rem;
  border-radius: 0.25rem;
}
.AkvaComboBox-module__akvaCombobox__item__highlighted___SJmfF {
  background-color: var(--color-background-hover);
}

.AkvaComboBox-module__noOutline___7AhQe {
  outline: 0;
}

.AkvaComboBox-module__akvaCombobox__label___2Gw5T {
  color: var(--color-text-4);
  margin-bottom: 0.25rem;
}
/*  imported from AkvaInlineButton.module.css  */

.AkvaInlineButton-module__inlineButton___ylmWx {
  cursor: pointer;
  font-size: var(--sizing-font-small);
  height: 1.25rem;
  font-weight: normal;
  line-height: 1.25rem;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  color: var(--color-primary-1);
}
.AkvaInlineButton-module__inlineButton___ylmWx:disabled {
  color: var(--color-primary-4);
  cursor: default;
}
.AkvaInlineButton-module__inlineButton___ylmWx:enabled:hover {
  color: var(--color-primary-2);
}

.AkvaInlineButton-module__inlineButton___ylmWx:enabled:active {
  color: var(--color-primary-2);
  font-weight: bold;
}
/*  imported from AkvaInput.module.css  */

.AkvaInput-module__inputContainer___GJMgU {
  display: flex;
  flex-direction: column;
}

.AkvaInput-module__inputWithUnit___1l6_c {
  display: flex;
  width: var(--input-width);
}

.AkvaInput-module__input___2i5mE {
  --input-width: 8rem;
  --input-height: 2rem;
  --font-size: var(--sizing-font-normal);

  display: flex;
  flex-direction: column;
  position: relative;
  line-height: normal;
  gap: 0.25rem;
}

.AkvaInput-module__input___2i5mE[data-label-left='true'] {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.AkvaInput-module__input___2i5mE[data-input-large='true'] {
  --input-width: 12rem;
  --input-height: 2.375rem;
  --font-size: var(--sizing-font-medium);
}

.AkvaInput-module__input___2i5mE > label {
  color: var(--color-text-4);
  font-size: var(--sizing-font-small);
}
.AkvaInput-module__inputLabelLeft___278Ed > label {
  width: 10rem;
  margin-right: 1rem;
}

.AkvaInput-module__input___2i5mE input {
  height: var(--input-height);
  width: 100%;
  padding: 0 0.5rem;
  outline: none;
  border: 1px solid var(--color-neutral-6);
  border-radius: 0.25rem;
  background-color: var(--color-neutral-2);
  color: var(--color-text-2);
  font-size: var(--font-size);
  transition: border-color 250ms, color 250ms;
  -moz-appearance: textfield;
}

.AkvaInput-module__input___2i5mE[data-has-unit='true'] input {
  border-radius: 0.25rem 0 0 0.25rem;
}

.AkvaInput-module__input___2i5mE:hover input {
  border-color: var(--color-neutral-7);
}

.AkvaInput-module__input___2i5mE input:focus {
  border-color: var(--color-primary-1);
}

.AkvaInput-module__input___2i5mE[data-input-error='true'] input {
  border-color: var(--color-error);
}

.AkvaInput-module__input___2i5mE input:disabled {
  border-color: var(--color-neutral-6);
  color: var(--color-text-5);
}

.AkvaInput-module__input___2i5mE input:focus::placeholder {
  color: transparent;
}
.AkvaInput-module__inputError___sbjeZ input,
.AkvaInput-module__inputError___sbjeZ input:focus {
  border: 1px solid var(--color-error);
}

.AkvaInput-module__input___2i5mE input[type='number'] {
  text-align: right;
}
.AkvaInput-module__input___2i5mE input[type='number']::-webkit-inner-spin-button,
.AkvaInput-module__input___2i5mE input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.AkvaInput-module__unitBox___1bPV5 {
  display: grid;
  place-content: center;
  min-width: 2.25rem;

  background-color: var(--color-neutral-2);
  color: var(--color-text-4);

  font-size: var(--font-size);
  white-space: nowrap;
  height: var(--input-height);
  border: 1px solid var(--color-neutral-6);

  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: none;

  padding: 0 0.5rem;
  transition: border-color 250ms, color 250ms;
}

.AkvaInput-module__input___2i5mE:hover .AkvaInput-module__unitBox___1bPV5 {
  border-color: var(--color-neutral-7);
}

.AkvaInput-module__input___2i5mE[data-input-error='false'] input:focus + .AkvaInput-module__unitBox___1bPV5 {
  border-color: var(--color-primary-1);
}
.AkvaInput-module__input___2i5mE[data-input-error='true'] .AkvaInput-module__unitBox___1bPV5 {
  border-color: var(--color-error);
}

.AkvaInput-module__input___2i5mE input:disabled + .AkvaInput-module__unitBox___1bPV5 {
  border-color: var(--color-neutral-6);
  color: var(--color-text-5);
  cursor: default;
}
.AkvaInput-module__inputError___sbjeZ input:focus + .AkvaInput-module__unitBox___1bPV5 {
  border-color: var(--color-error);
}
/*  imported from AkvaListLink.module.css  */

.AkvaListLink-module__listLink___1v35n {
  height: 2.5rem;
  width: 10.5rem;

  overflow: hidden;
  white-space: nowrap;

  display: flex;

  align-items: center;

  padding: 0.5rem;
  gap: 0.5rem;

  cursor: default;
  font-size: var(--sizing-font-normal);
  font-weight: normal;
  line-height: 1.5rem;
  border: none;
  border-radius: 0.25rem;
  color: var(--color-text-4);
  background-color: var(--color-neutral-2);
}

.AkvaListLink-module__listLink___1v35n[data-variant='icon'][data-label-position='right'] {
  flex-direction: row-reverse;
}
.AkvaListLink-module__listLink___1v35n[data-variant='icon'][data-label-position='right'][data-spacing='packed'] {
  justify-content: flex-end;
}

.AkvaListLink-module__listLink___1v35n[data-spacing='space-between'] {
  justify-content: space-between;
}

.AkvaListLink-module__listLink___1v35n svg * {
  fill: var(--color-tertiary);
}

.AkvaListLink-module__listLink___1v35n:hover {
  color: var(--color-text-2);
  background-color: var(--color-neutral-4);
  cursor: pointer;
}
.AkvaListLink-module__listLink___1v35n:active {
  background-color: var(--color-neutral-5);
}
.AkvaListLink-module__listLink___1v35n[data-variant='icon']:hover svg * {
  fill: var(--color-text-2);
}
.AkvaListLink-module__listLink___1v35n[data-variant='icon']:active svg * {
  fill: var(--color-text-2);
}
/*  imported from AkvaMenuLink.module.css  */

.AkvaMenuLink-module__menuLink___3t6oz {
  height: 2.5rem;
  width: 100%;

  display: flex;
  align-items: center;

  gap: 0.5rem;
  padding: 0.5rem;
  justify-content: flex-start;

  cursor: pointer;
  font-size: var(--sizing-font-normal);
  font-weight: bold;
  line-height: 1.5rem;

  border: none;
  border-radius: 0.25rem;

  color: var(--color-text-4);
  background-color: var(--color-neutral-2);
}

/* Sizing and padding */

.AkvaMenuLink-module__menuLink___3t6oz[data-size='small'] {
  height: 2rem;
  padding: 0.375rem 0.5rem;
  font-weight: normal;
}

.AkvaMenuLink-module__menuLink___3t6oz[data-label-position='left'] {
  flex-direction: row-reverse;
}
.AkvaMenuLink-module__menuLink___3t6oz[data-spacing='space-between'] {
  justify-content: space-between;
}
.AkvaMenuLink-module__menuLink___3t6oz[data-label-position='left'][data-spacing='packed'] {
  justify-content: flex-end;
}

.AkvaMenuLink-module__menuLink___3t6oz[data-size='normal'][data-collapsed='true'] {
  width: 2.5rem;
  padding: 0.5rem;
  background: transparent;
  transition: width 0.4s ease;
}

/* Color Styling */

.AkvaMenuLink-module__menuLink___3t6oz svg * {
  fill: var(--color-tertiary);
}

.AkvaMenuLink-module__menuLink___3t6oz:hover {
  color: var(--color-text-2);
  background-color: var(--color-neutral-4);
}

.AkvaMenuLink-module__menuLink___3t6oz:hover svg * {
  fill: var(--color-text-2);
}

.AkvaMenuLink-module__menuLink___3t6oz[data-active='true'],
.AkvaMenuLink-module__menuLink___3t6oz:active {
  color: var(--color-primary-2);
  background-color: var(--color-neutral-5);
}

.AkvaMenuLink-module__menuLink___3t6oz[data-active='true'] svg *,
.AkvaMenuLink-module__menuLink___3t6oz:active svg * {
  fill: var(--color-primary-2);
}

.AkvaMenuLink-module__menuLink___3t6oz[data-collapsed='true'][data-size='normal']:hover {
  background-color: var(--color-neutral-4);
}
.AkvaMenuLink-module__menuLink___3t6oz[data-collapsed='true'][data-size='normal']:active,
.AkvaMenuLink-module__menuLink___3t6oz[data-collapsed='true'][data-size='normal'][data-active='true'] {
  background-color: var(--color-neutral-5);
}/*  imported from AkvaModal.module.css  */

.AkvaModal-module__container___b9n5f {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(var(--color-black-rgb), 0.5);
  z-index: 1000;
  display: grid;
  place-content: center;
}

.AkvaModal-module__header___DsK9H {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-neutral-2);
  padding: 0.75rem 1rem;
  max-width: 25rem;
  border-radius: 0.25rem 0.25rem 0 0;
}
.AkvaModal-module__header___DsK9H h2 {
  font-size: 1rem;
}

.AkvaModal-module__content___17lAr {
  background-color: var(--color-neutral-4);
  padding: 1rem;
  max-width: 25rem;
  font-size: var(--sizing-font-normal);
  border-radius: 0 0 0.25rem 0.25rem;
}

.AkvaModal-module__buttons___yJRV5 {
  display: grid;
  justify-content: end;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 2rem;
}

@keyframes AkvaModal-module__spin___3lNe9 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.AkvaModal-module__loader___3xybt {
  vertical-align: middle;
  display: inline-block;
  border: 2px solid var(--color-neutral-5);
  border-top: 2px solid var(--color-primary-1);
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: AkvaModal-module__spin___3lNe9 0.8s linear infinite;
}
/*  imported from AkvaMultiSelect.module.css  */

.AkvaMultiSelect-module__akvaMultiSelect___3lz4Y {
  color: var(--color-text-2);
  display: inline-block;
  position: relative;
  padding: 0;
  text-align: left;
}

.AkvaMultiSelect-module__akvaMultiSelect__button___zHwms {
  background: var(--color-neutral-2);
  border: 0;
  color: var(--color-text-4);

  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--sizing-font-normal);
  padding: 0.25rem 0.375rem 0.25rem 0.5rem;
  outline: none;
  transition: all 200ms;
  min-width: 12rem;
  border-radius: 0.25rem;
}

.AkvaMultiSelect-module__akvaMultiSelect__button___zHwms[data-is-empty='true'] {
  color: var(--color-neutral-7);
}

.AkvaMultiSelect-module__akvaMultiSelect__button___zHwms:not(.AkvaMultiSelect-module__akvaMultiSelect__buttonActive___1JAbd):enabled:hover {
  cursor: pointer;
  border-color: var(--color-neutral-7);
}
.AkvaMultiSelect-module__akvaMultiSelect__buttonBorder___1OkXm {
  border: 1px solid var(--color-neutral-6);
}
.AkvaMultiSelect-module__akvaMultiSelect__buttonActive___1JAbd {
  border: 1px solid var(--color-primary-1);
  color: var(--color-text-2);
}

.AkvaMultiSelect-module__akvaMultiSelect__buttonIcon___3UZpQ {
  display: inline-block;
  margin-left: var(--sizing-small);
}

.AkvaMultiSelect-module__akvaMultiSelect__buttonIconOpen___2-vf0 {
  transform: rotate(180deg);
}

.AkvaMultiSelect-module__akvaMultiSelect__dropdown___viFBh {
  border-radius: 0.25rem;
  background: var(--color-neutral-2);

  margin-top: 2px;
  list-style-type: none;

  overflow-y: auto;
  overflow-x: hidden;
  min-width: max(100%, 12rem);
  max-width: 20rem;
  max-height: 15rem;
  /* Use really high zIndex to ensure on top */
  z-index: 9999;
  padding-top: 0.25rem;
}
.AkvaMultiSelect-module__akvaMultiSelect__dropdown___viFBh ul {
  min-width: 100%;
}

.AkvaMultiSelect-module__akvaMultiSelect__item___2XnUU {
  list-style-type: none;
  font-size: var(--sizing-font-normal);

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0.25rem 0.25rem;
  border-radius: 0.25rem;
}
.AkvaMultiSelect-module__akvaMultiSelect__item___2XnUU:hover {
  background-color: var(--color-neutral-4);
}
.AkvaMultiSelect-module__akvaMultiSelect__item__highlighted___286y1 {
  background-color: rgba(var(--color-neutral-6-rgb), 0.25);
}

/* Making AkvaCheckbox component to fit pixel to pixel to
list item of drop down, to make the entire area clickable
and enable hover styling of checkbox. */

.AkvaMultiSelect-module__akvaMultiSelect__item___2XnUU label {
  padding: var(--sizing-small) var(--sizing-small);
  width: 100%;
  height: 100%;

  /* Disabling pointer-events for the checkbox component, because
  otherwise onStateChange event gets triggered twice and as a 
  result selected items always remain unselected. */

  pointer-events: none;
}

/* Since pointer-events have been set to none above, now need to 
set hover state once again */

.AkvaMultiSelect-module__akvaMultiSelect__item___2XnUU:hover span {
  border-color: var(--color-text-4);
}

/* To avoid label text overflow the chevron icon and disturb
   overall styling within the multiselect button, had to put
   the label within a div with specific width to enable ellipsis 
   overflow */

.AkvaMultiSelect-module__labelText___3Fhiu {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
/*  imported from AkvaRadioButton.module.css  */

.AkvaRadioButton-module__radioButton___3XYjx {
  --button-size: 1.5rem;
  --height: calc(var(--button-size) + var(--top-margin) * 2);
  --top-margin: var(--sizing-tiny);

  --button-border-width: 1px;
  --button-inner-height: 0.75rem;
  --button-inner-offset: calc(
    (
        var(--button-size) - var(--button-border-width) * 2 -
          var(--button-inner-height)
      ) / 2
  );
  --label-font-size: 1rem;

  height: var(--height);

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;

  font-size: var(--label-font-size);
  cursor: pointer;
  transition: color 500ms;
}

.AkvaRadioButton-module__radioButton___3XYjx[data-label-position='right'] {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.AkvaRadioButton-module__radioButton___3XYjx[data-label-position='right'][data-spacing='packed'] {
  justify-content: flex-end;
}
.AkvaRadioButton-module__radioButton___3XYjx[data-spacing='packed'] {
  justify-content: flex-start;
}

.AkvaRadioButton-module__radioButton___3XYjx[data-spacing='space-between'] {
  justify-content: space-between;
}

.AkvaRadioButton-module__radioButton___3XYjx[data-disabled='true'] {
  cursor: default;
  color: var(--color-text-5);
}

.AkvaRadioButton-module__radioButton___3XYjx input[type='radio'] {
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
  z-index: -999;
}

.AkvaRadioButton-module__radioButtonCustom___zGxSR {
  display: block;
  position: relative;
  border: var(--button-border-width) solid var(--color-tertiary);
  border-radius: 100%;
  height: var(--button-size);
  width: var(--button-size);

  background-color: var(--color-neutral-2);
}
.AkvaRadioButton-module__radioButton___3XYjx:hover input[type='radio']:enabled ~ .AkvaRadioButton-module__radioButtonCustom___zGxSR {
  border-color: var(--color-text-4);
}

.AkvaRadioButton-module__radioButton___3XYjx input[type='radio']:disabled ~ .AkvaRadioButton-module__radioButtonCustom___zGxSR {
  border-color: var(--color-neutral-7);
}

.AkvaRadioButton-module__radioButtonCustom___zGxSR::before {
  display: block;
  position: relative;
  content: '';
  border-radius: 100%;
  height: var(--button-inner-height);
  width: var(--button-inner-height);
  top: var(--button-inner-offset);
  left: var(--button-inner-offset);
  transition: background 200ms;
}

.AkvaRadioButton-module__radioButton___3XYjx input[type='radio']:checked ~ .AkvaRadioButton-module__radioButtonCustom___zGxSR::before {
  background-color: var(--color-primary-1);
}

.AkvaRadioButton-module__radioButton___3XYjx:hover
  input[type='radio']:enabled:checked
  ~ .AkvaRadioButton-module__radioButtonCustom___zGxSR::before {
  background-color: var(--color-primary-2);
}

.AkvaRadioButton-module__radioButton___3XYjx input[type='radio']:disabled:checked ~ .AkvaRadioButton-module__radioButtonCustom___zGxSR::before {
  background-color: var(--color-primary-4);
}
/*  imported from AkvaSearchBar.module.css  */

.AkvaSearchBar-module__container___wLsXb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
}

.AkvaSearchBar-module__container___wLsXb[data-label-position='top'] {
  flex-direction: column;
  align-items: flex-start;
}

.AkvaSearchBar-module__searchField___gZ68y {
  display: flex;
  --icon-width: 2.5rem;
  --clear-button-width: 2rem;
  --left-padding: var(--icon-width);
  --right-padding: var(--clear-button-width);
}

.AkvaSearchBar-module__clearButton___3h0wd {
  all: unset;
  margin-left: calc(var(--right-padding) * -1);
  width: var(--clear-button-width);
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.AkvaSearchBar-module__clearButton___3h0wd:focus-visible {
  outline: 1px solid var(--color-primary-1);
}

.AkvaSearchBar-module__icon___3eC6Q {
  position: relative;
  z-index: var(--zIndex-top);
  margin-right: calc(var(--left-padding) * -1);
  width: var(--icon-width);
  display: flex;
  justify-content: center;
  align-items: center;
}

.AkvaSearchBar-module__input___2YCBY {
  color: var(--color-text-2);
  background-color: var(--color-neutral-2);

  padding: 0.25rem var(--right-padding) 0.25rem var(--left-padding);
  border: 1px solid var(--color-neutral-6);
  border-radius: 0.25rem;
  font-size: 1rem;
  transition: border-color 250ms, color 250ms;
}

.AkvaSearchBar-module__input___2YCBY:enabled::placeholder {
  color: var(--color-neutral-7);
}
.AkvaSearchBar-module__input___2YCBY[data-has-error='false']:not(:active):not(:focus):enabled {
  border-color: var(--color-neutral-6);
}
.AkvaSearchBar-module__input___2YCBY[data-has-error='false']:not(:active):not(:focus):enabled:hover {
  border-color: var(--color-neutral-7);
}

.AkvaSearchBar-module__input___2YCBY::-webkit-search-cancel-button,
.AkvaSearchBar-module__input___2YCBY::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

.AkvaSearchBar-module__input___2YCBY[data-has-error='false']:active:enabled,
.AkvaSearchBar-module__input___2YCBY[data-has-error='false']:focus {
  border-color: var(--color-primary-1);
}

.AkvaSearchBar-module__input___2YCBY:disabled {
  border-color: var(--color-neutral-6);
  color: var(--color-text-5);
}

.AkvaSearchBar-module__clearButton___3h0wd[data-is-disabled='true'] svg * {
  fill: var(--color-text-5);
}

input[data-has-error='true'] {
  border-color: var(--color-error);
}
/*  imported from AkvaSelect.module.css  */

.AkvaSelect-module__akvaSelect___2d_A6 {
  color: var(--color-text-2);
  display: inline-block;
  position: relative;
  padding: 0;
  text-align: left;
}

.AkvaSelect-module__akvaSelect__button___35Qns {
  background: var(--color-neutral-2);
  border: 1px solid var(--color-neutral-6);
  border-radius: 0.25rem;
  color: var(--color-text-4);
  min-width: 12rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--sizing-font-normal);
  padding: var(--sizing-tiny) var(--sizing-small);
  transition: all 200ms;
}

.AkvaSelect-module__akvaSelect__text___PoKpQ {
margin-right: auto;
}

.AkvaSelect-module__akvaSelect__button___35Qns:enabled {
  cursor: pointer;
}

.AkvaSelect-module__akvaSelect__button___35Qns:disabled {
  color: var(--color-text-5);
}

.AkvaSelect-module__akvaSelect__button___35Qns:not(.AkvaSelect-module__akvaSelect__buttonOpen___3Gpgg):enabled:hover {
  border-color: var(--color-neutral-7);
}
.AkvaSelect-module__akvaSelect__buttonOpen___3Gpgg {
  border-color: var(--color-primary-1);
  color: var(--color-text-2);
}

.AkvaSelect-module__akvaSelect__buttonIcon___1VI1y {
  display: inline-block;
  margin-left: var(--sizing-small);
}

.AkvaSelect-module__akvaSelect__buttonIconOpen___2_zNS {
  transform: rotate(180deg);
}

.AkvaSelect-module__akvaSelect__dropdown___ARv6N {
  list-style-type: none;
  background: var(--color-neutral-2);
  overflow: hidden;
  width: 100%;
  /* Use really high zIndex to ensure on top */
  z-index: 9999;
  border-radius: 0.25rem;
}
.AkvaSelect-module__akvaSelect__dropdown___ARv6N ul {
  width: 100%;
  padding-top: 0.25rem;
}
.AkvaSelect-module__akvaSelect__item___1Y9IJ {
  list-style-type: none;
  font-size: var(--sizing-font-normal);
  padding: var(--sizing-small) var(--sizing-normal);
  cursor: pointer;
  margin: 0 0.25rem 0.25rem;
  border-radius: 0.25rem;
}
.AkvaSelect-module__akvaSelect__item__highlighted___12ra4 {
  background-color: var(--color-neutral-4);
}

.AkvaSelect-module__akvaSelect__item___1Y9IJ:active {
  background-color: var(--color-neutral-5);
}

.AkvaSelect-module__downshiftContainer___1obr2 {
  outline: 0;
}
/*   imported from AkvaTextButton.module.css  */

.AkvaTextButton-module__button___iuVhi {
  height: 2.5rem; /*  40px */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-size: var(--sizing-font-normal);
  font-weight: bold;
  line-height: 1rem;
  border: none;
  background: transparent;
  border-radius: 0.25rem;
  padding: 0.5rem 1.5rem;
}
.AkvaTextButton-module__button___iuVhi[data-icon-position='left'] {
  flex-direction: row-reverse;
}

/* Small size text button paddings */

.AkvaTextButton-module__button___iuVhi[data-icon-size='small'] {
  height: 2rem;
  font-size: var(--sizing-font-small);
  padding: 0.375rem 1rem;
}
.AkvaTextButton-module__button___iuVhi[data-icon-size='small'][data-icon-variant='icon-button'] {
  padding: 0.375rem;
}
.AkvaTextButton-module__button___iuVhi[data-icon-size='small'][data-icon-position='left'][data-has-icon='true'][data-icon-variant='text-button'] {
  padding: 0.375rem 1rem 0.375rem 0.75rem;
}

.AkvaTextButton-module__button___iuVhi[data-icon-size='small'][data-icon-position='right'][data-has-icon='true'][data-icon-variant='text-button'] {
  padding: 0.375rem 0.75rem 0.375rem 1rem;
}

/* Normal size text button paddings */

.AkvaTextButton-module__button___iuVhi[data-icon-size='normal'][data-icon-variant='icon-button'] {
  padding: 0.5rem;
}

.AkvaTextButton-module__button___iuVhi[data-icon-size='normal'][data-icon-position='left'][data-has-icon='true'][data-icon-variant='text-button'] {
  padding: 0.5rem 1.5rem 0.5rem 1.25rem;
}
.AkvaTextButton-module__button___iuVhi[data-icon-size='normal'][data-icon-position='right'][data-has-icon='true'][data-icon-variant='text-button'] {
  padding: 0.5rem 1.25rem 0.5rem 1.5rem;
}

/* Button events and colors */

.AkvaTextButton-module__button___iuVhi:hover:enabled {
  background: rgba(255, 255, 255, 0.06);
}
.AkvaTextButton-module__button___iuVhi:active:enabled {
  background: rgba(255, 255, 255, 0.12);
}

.AkvaTextButton-module__blue___3pQ6V {
  color: var(--color-primary-1);
}
.AkvaTextButton-module__blue___3pQ6V svg * {
  fill: var(--color-primary-1);
}

.AkvaTextButton-module__blue___3pQ6V:hover {
  color: var(--color-primary-2);
}
.AkvaTextButton-module__blue___3pQ6V:hover svg * {
  fill: var(--color-primary-2);
}

.AkvaTextButton-module__blue___3pQ6V:active {
  color: var(--color-primary-2);
}
.AkvaTextButton-module__blue___3pQ6V:active svg * {
  fill: var(--color-primary-2);
}

.AkvaTextButton-module__blue___3pQ6V:disabled {
  color: var(--color-primary-4);
  cursor: default;
}
.AkvaTextButton-module__blue___3pQ6V:disabled svg * {
  fill: var(--color-primary-4);
}

.AkvaTextButton-module__white___3UAEU {
  color: var(--color-text-4);
}
.AkvaTextButton-module__white___3UAEU svg * {
  fill: var(--color-text-4);
}

.AkvaTextButton-module__white___3UAEU:hover {
  color: var(--color-text-2);
}
.AkvaTextButton-module__white___3UAEU:hover svg * {
  fill: var(--color-text-2);
}

.AkvaTextButton-module__white___3UAEU:active {
  color: var(--color-text-2);
}
.AkvaTextButton-module__white___3UAEU:active svg * {
  fill: var(--color-text-2);
}

.AkvaTextButton-module__white___3UAEU:disabled {
  color: var(--color-text-5);
  cursor: default;
}
.AkvaTextButton-module__white___3UAEU:disabled svg * {
  fill: var(--color-text-5);
}
/*  imported from AkvaTextField.module.css  */

.AkvaTextField-module__container___11zAW {
  display: flex;
  gap: var(--sizing-tiny);
  flex: 1 0;
}

.AkvaTextField-module__container___11zAW[data-variant='normal'] {
  align-items: center;
}

.AkvaTextField-module__container___11zAW[data-label-position='top'] {
  flex-direction: column;
  align-items: stretch;
}

.AkvaTextField-module__textContainer___WfrRW {
  background-color: var(--color-neutral-2);
  border: 1px solid var(--color-neutral-6);
  border-radius: var(--sizing-border-radius);
  transition: border-color 250ms;
  overflow: hidden;
  flex: 1 0;
}

.AkvaTextField-module__textContainer___WfrRW input,
.AkvaTextField-module__textContainer___WfrRW textarea {
  resize: none;
  outline: none;
  cursor: auto;
  border: none;
  width: 100%;
  color: var(--color-text-2);
  padding: var(--sizing-tiny) var(--sizing-small);
  font-size: var(--sizing-font-normal);
  transition: color 250ms;
  border-radius: var(--sizing-border-radius);
  background-color: transparent;
}

.AkvaTextField-module__input___1cRiJ:enabled::placeholder {
  color: var(--color-neutral-7);
}

.AkvaTextField-module__textContainer___WfrRW[data-disabled='false'][data-has-error='false']:not(:active):not(
    :focus-within
  ):hover {
  border-color: var(--color-neutral-7);
}

.AkvaTextField-module__textContainer___WfrRW[data-has-error='false'][data-disabled='false']:active,
.AkvaTextField-module__textContainer___WfrRW[data-has-error='false'][data-disabled='false']:focus-within {
  border-color: var(--color-primary-1);
}

.AkvaTextField-module__input___1cRiJ:disabled {
  color: var(--color-text-5);
  cursor: default;
}

.AkvaTextField-module__textContainer___WfrRW[data-has-error='true'] {
  border-color: var(--color-error);
}

/* To round the scrollbar edges in Safari browser */
textarea::-webkit-scrollbar-track {
  border-radius: 0 3px 3px 0;
}
/*  imported from AkvaTextLink.module.css  */

.AkvaTextLink-module__textlink___3Ajd7 {
  background-color: transparent;
  color: var(--color-accent-1);
  cursor: pointer;
  font-size: var(--sizing-font-normal);
  font-weight: normal;
  line-height: 1rem;
  border: none;
  outline: none;
}

.AkvaTextLink-module__textlink___3Ajd7:enabled:hover {
  color: var(--color-accent-1);
  font-weight: bold;
  text-decoration: underline;
}
.AkvaTextLink-module__textlink___3Ajd7:enabled:active {
  color: var(--color-accent-1);
  font-weight: bold;
}
.AkvaTextLink-module__textlink___3Ajd7:disabled {
  color: var(--color-secondary-4);
  cursor: default;
}
/*  imported from AkvaToggleButton.module.css  */

/* Setting max width for label to avoid toggle slider handle overflow for longer labels */
.AkvaToggleButton-module__label___2IErc {
  max-width: calc(100% - 3.5rem);
  cursor: pointer;
}
.AkvaToggleButton-module__toggleContainer___2ErlE {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  cursor: pointer;
  transition: 0.5s ease color;
}

.AkvaToggleButton-module__toggleContainer___2ErlE[data-spacing='packed'] {
  justify-content: flex-start;
}

.AkvaToggleButton-module__toggleContainer___2ErlE[data-disabled='true'] {
  cursor: default;
  color: var(--color-text-5);
}

.AkvaToggleButton-module__switch___vp7S8 {
  background-color: transparent;
  border: none;
  position: relative;
  display: inline-flex;
  width: 2.5rem;
  height: 1.5rem;
}

/*Slider*/

.AkvaToggleButton-module__slider___i_6eZ {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-secondary-2);
  transition: 0.5s ease background-color;
  border-radius: 2rem;
}

.AkvaToggleButton-module__checked___1i07Y .AkvaToggleButton-module__slider___i_6eZ {
  background-color: var(--color-primary-1);
}

.AkvaToggleButton-module__switch___vp7S8:disabled .AkvaToggleButton-module__slider___i_6eZ {
  background-color: var(--color-neutral-6);
  cursor: default;
}
.AkvaToggleButton-module__checked___1i07Y:disabled .AkvaToggleButton-module__slider___i_6eZ {
  background-color: var(--color-primary-4);
}

/*Slider Handle*/

.AkvaToggleButton-module__slider___i_6eZ:before {
  position: absolute;
  content: '';
  height: 1.25rem;
  width: 1.25rem;
  left: 2px;
  bottom: 2px;
  background-color: var(--color-text-2);
  transition: 0.2s ease transform, 0.5s ease background-color;
  border-radius: 50%;
}

.AkvaToggleButton-module__switch___vp7S8:disabled .AkvaToggleButton-module__slider___i_6eZ:before {
  background-color: rgba(255, 255, 255, 0.5);
  cursor: default;
}

.AkvaToggleButton-module__checked___1i07Y:disabled .AkvaToggleButton-module__slider___i_6eZ:before {
  background-color: rgba(255, 255, 255, 0.5);
}

.AkvaToggleButton-module__checked___1i07Y .AkvaToggleButton-module__slider___i_6eZ:before {
  transform: translateX(1rem);
}
/*  imported from akva-error-message.module.css  */

.akva-error-message-module__error___3oJCu {
  --line-height: 1.25rem;
  display: flex;
  gap: var(--sizing-tiny);
  margin-top: var(--sizing-tiny);
  margin-bottom: var(--sizing-tiny);
}

.akva-error-message-module__errorTextContainer___2nfEU {
  line-height: var(--line-height);
  font-size: var(--sizing-font-small);
  color: var(--color-text-2);
  font-weight: normal;
}

.akva-error-message-module__errorTextContainerWithText___rnOxG {
  font-weight: bold;
  margin-bottom: var(--sizing-tiny);
}
.akva-error-message-module__errorDetail___2ZjSR {
  font-size: var(--sizing-font-small);
}

:root {
  --color-neutral-0: rgb(18, 22, 24);
  --color-neutral-0-rgb: 18, 22, 24;
  --color-neutral-1: rgb(24, 32, 37);
  --color-neutral-1-rgb: 24, 32, 37;
  --color-neutral-2: rgb(27, 37, 43);
  --color-neutral-2-rgb: 27, 37, 43;
  --color-neutral-3: rgb(32, 43, 50);
  --color-neutral-3-rgb: 32, 43, 50;
  --color-neutral-4: rgb(38, 50, 60);
  --color-neutral-4-rgb: 38, 50, 60;
  --color-neutral-5: rgb(45, 58, 71);
  --color-neutral-5-rgb: 45, 58, 71;
  --color-neutral-6: rgb(62, 75, 86);
  --color-neutral-6-rgb: 62, 75, 86;
  --color-neutral-7: rgb(94, 117, 131);
  --color-neutral-7-rgb: 94, 117, 131;

  --color-text-1: rgb(229, 236, 242);
  --color-text-1-rgb: 229, 236, 242;
  --color-text-2: rgb(218, 228, 236);
  --color-text-2-rgb: 218, 228, 236;
  --color-text-3: rgb(206, 220, 231);
  --color-text-3-rgb: 206, 220, 231;
  --color-text-4: rgb(183, 203, 219);
  --color-text-4-rgb: 183, 203, 219;
  --color-text-5: rgb(137, 141, 144);
  --color-text-5-rgb: 137, 141, 144;
  --color-text-6: rgb(181, 181, 181);
  --color-text-6-rgb: 181, 181, 181;

  --color-success: rgb(16, 193, 105);
  --color-success-rgb: 16, 193, 105;
  --color-warning: rgb(246, 200, 0);
  --color-warning-rgb: 246, 200, 0;
  --color-error: rgb(234, 86, 0);
  --color-error-rgb: 234, 86, 0;

  --color-primary-1: rgb(0, 187, 228);
  --color-primary-1-rgb: 0, 187, 228;
  --color-primary-2: rgb(76, 223, 255);
  --color-primary-2-rgb: 76, 223, 255;
  --color-primary-3: rgb(0, 153, 187);
  --color-primary-3-rgb: 0, 153, 187;
  --color-primary-4: rgb(58, 116, 129);
  --color-primary-4-rgb: 58, 116, 129;

  --color-secondary-1: rgb(68, 89, 108);
  --color-secondary-1-rgb: 68, 89, 108;
  --color-secondary-2: rgb(84, 110, 134);
  --color-secondary-2-rgb: 84, 110, 134;
  --color-secondary-3: rgb(60, 78, 95);
  --color-secondary-3-rgb: 60, 78, 95;
  --color-secondary-4: rgb(60, 66, 72);
  --color-secondary-4-rgb: 60, 66, 72;

  --color-graph-1: rgb(120, 120, 255);
  --color-graph-1-rgb: 120, 120, 255;
  --color-graph-2: rgb(10, 155, 245);
  --color-graph-2-rgb: 10, 155, 245;
  --color-graph-3: rgb(0, 190, 235);
  --color-graph-3-rgb: 0, 190, 235;
  --color-graph-4: rgb(0, 220, 220);
  --color-graph-4-rgb: 0, 220, 220;
  --color-graph-5: rgb(60, 240, 180);
  --color-graph-5-rgb: 60, 240, 180;
  --color-graph-6: rgb(250, 225, 0);
  --color-graph-6-rgb: 250, 225, 0;
  --color-graph-7: rgb(242, 200, 62);
  --color-graph-7-rgb: 242, 200, 62;
  --color-graph-8: rgb(225, 167, 56);
  --color-graph-8-rgb: 225, 167, 56;
  --color-graph-9: rgb(225, 126, 43);
  --color-graph-9-rgb: 225, 126, 43;
  --color-graph-10: rgb(250, 90, 30);
  --color-graph-10-rgb: 250, 90, 30;

  --color-tertiary: rgb(101, 131, 158);
  --color-tertiary-rgb: 101, 131, 158;

  --color-accent-1: rgb(61, 189, 181);
  --color-accent-1-rgb: 61, 189, 181;

  --color-black: rgb(0, 0, 0);
  --color-black-rgb: 0, 0, 0;
  --color-white: rgb(255, 255, 255);
  --color-white-rgb: 255, 255, 255;

  --color-dropdown-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 0 14px 2px rgba(0, 0, 0, 0.12);
}

:root {
  --sizing-tiny: 0.25rem;
  --sizing-small: 0.5rem;
  --sizing-medium: 0.75rem;
  --sizing-normal: 1rem;
  --sizing-large: 1.5rem;
  --sizing-xlarge: 2rem;

  --sizing-font-xsmall: 0.75rem;
  --sizing-font-small: 0.875rem;
  --sizing-font-normal: 1rem;
  --sizing-font-medium: 1.25rem;
  --sizing-font-large: 1.5rem;
  --sizing-font-xlarge: 1.75rem;

  --sizing-icon-tiny: 1rem;
  --sizing-icon-small: 1.5rem;
  --sizing-icon-normal: 2rem;

  --sizing-border-radius: 0.25rem;
}

/* latin-ext */
@font-face {
  font-family: '__DM_Sans_4b5a0e';
  font-style: italic;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/bcdb820d2d1f1b99-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_4b5a0e';
  font-style: italic;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/f67bf89ff1d156f0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__DM_Sans_4b5a0e';
  font-style: normal;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/adde053269573569-s.p.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_4b5a0e';
  font-style: normal;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/3e3cf2ee9aa3f174-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_4b5a0e';src: local("Arial");ascent-override: 94.37%;descent-override: 29.49%;line-gap-override: 0.00%;size-adjust: 105.12%
}.__className_4b5a0e {font-family: '__DM_Sans_4b5a0e', '__DM_Sans_Fallback_4b5a0e', Roboto, Helvetica, sans-serif
}

